import React from 'react';
import Layout from "../_components/layout/layout";
import About from "../_components/about/about";

const AboutPage = () => {
    return (
        <Layout>
            <About/>
        </Layout>
    );
};


export default AboutPage;